<template>
  <div class="VRScene">
    <div id="container"></div>
    <!-- <div class="box">
      <div class="title">模型组件</div>
      <div class="title">模型工具</div>
      <ul class="tool">
        <li @click="imgbtn = 1">
          <img
            v-show="imgbtn == 1"
            src="../../img/three/8.png"
            alt=""
            style="width: 0.74rem"
          />
          <img
            v-show="imgbtn != 1"
            :class="{ active: imgbtn != 1 }"
            src="../../img/three/77.png"
            alt=""
            style="width: 0.74rem"
          />
          <span :class="{ spancolor: imgbtn == 1 }">模型操作</span>
        </li>

        <li @click="imgbtn = 2">
          <img
            v-show="imgbtn != 2"
            :class="{ active: imgbtn != 2 }"
            src="../../img/three/2.png"
            alt=""
            style="width: 0.74rem"
          />
          <img
            v-show="imgbtn == 2"
            src="../../img/three/3.png"
            alt=""
            style="width: 0.74rem"
          />
          <span :class="{ spancolor: imgbtn == 2 }">默认视图</span>
        </li>

        <li @click="imgbtn = 3">
          <img
            v-show="imgbtn != 3"
            :class="{ active: imgbtn != 3 }"
            src="../../img/three/4.png"
            alt=""
            style="width: 0.74rem"
          />
          <img
            v-show="imgbtn == 3"
            src="../../img/three/未标题-2.png"
            alt=""
            style="width: 0.74rem"
          />
          <span :class="{ spancolor: imgbtn == 3 }">爆炸视图</span>
        </li>

        <li @click="imgbtn = 4">
          <img
            v-show="imgbtn != 4"
            :class="{ active: imgbtn != 4 }"
            src="../../img/three/5.png"
            alt=""
            style="width: 0.74rem"
          />
          <img
            v-show="imgbtn == 4"
            src="../../img/three/6.png"
            alt=""
            style="width: 0.74rem"
          />
          <span :class="{ spancolor: imgbtn == 4 }">动画视图</span>
        </li>
      </ul>
    </div> -->
  </div>
</template>
<script>
import * as THREE from "three";
import $ from "jquery";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
export default {
  name: "VRScene",
  props: ["real"],
  data() {
    return {
      img: "",
      camera: null,
      scene: null,
      renderer: null,
      imgbtn: 1,
    };
  },
  mounted() {
    // this.camera
    // this.scene
    // this.renderer
    // this.init();
    // this.animate();
    // console.log(this.props.real);
  },
  methods: {
    init() {
      var container, mesh;
      var conWidth = $("#container").width();
      container = document.getElementById("container");
      this.camera = new THREE.PerspectiveCamera(
        65,
        window.innerWidth / window.innerHeight,
        1,
        1100
      );
      this.camera.target = new THREE.Vector3(0, 0, 0);
      this.scene = new THREE.Scene();
      var geometry = new THREE.SphereBufferGeometry(500, 40, 60);
      // var geometry = new THREE.SphereBufferGeometry( 100, 8, 8 );
      // invert the geometry on the x-axis so that all of the faces point inward
      geometry.scale(-1, 1, 1);
      let texture = new THREE.TextureLoader().load(this.img);
      this.material = new THREE.MeshBasicMaterial({ map: texture });
      this.material.needsUpdate = true;
      mesh = new THREE.Mesh(geometry, this.material);

      this.scene.add(mesh);

      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(conWidth, window.innerHeight);

      container.appendChild(this.renderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      //controls.update() must be called after any manual changes to the camera's transform
      this.camera.position.set(0, 20, 100);
      // this.camera.position.set(0, 10, 50);
      this.controls.update();

      window.addEventListener("resize", this.onWindowResize, false);
    },
    onWindowResize() {
      var conWidth = $("#container").width();
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(conWidth, window.innerHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },
  },
  watch: {
    real: function (newval, oldval) {
      this.img = String(newval);
      console.log(String(this.img));
      this.init();
      this.animate();
      console.log(oldval);
    },
  },
};
</script>
<style scoped lang="less">
.VRScene {
  width: 100%;
  .div {
    width: 80%;
    height: calc(100vh - 0.625in);
    // margin-top: 200px;
  }
  // .box {
  //   width: 332px;
  //   height: 100%;
  //   background-color: #ffffff;
  //   position: absolute;
  //   right: 0;
  //   top: 60/96in;
  //   .title {
  //     height: 33px;
  //     line-height: 33px;
  //     padding-left: 23px;
  //     background: #dddfe2;
  //     color: #4e4e4e;
  //     font-weight: bold;
  //     font-size: 18px;
  //   }
  //   .tool {
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //     padding: 14px 38px 0 48px;

  //     li {
  //       margin-right: 10px;
  //       margin-top: 52px;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;

  //       img {
  //         margin-bottom: 24px;
  //       }
  //       .active {
  //         box-shadow: 0 5px 23px rgba(29, 94, 255, 0.2);
  //         border-radius: 10px;
  //       }
  //       .spancolor {
  //         color: #1c5eff;
  //       }
  //     }
  //   }
  // }
}
</style>